export default [
  {
    id: 'efw',
    url: 'efwewef',
    mentorid: 32,
    menteeid: 32,
    dateOfPost: new Date(),

    // parsed data
    mentor: {
      name: 'Gabriel',
      middle: 'Muchiri',
      id: '23',
      url: 'efvrqaevadns',
      photo: '/img/user-photo.jpg',
      email: 'gramwauu@gmail.com',
    },
    mentee: {
      name: 'Christina',
      middle: 'Njeri',
      id: '23',
      url: 'efvrqaevadns',
      photo: '/img/user-photo.jpg',
      email: 'gramwauu@gmail.com',
    },
  }];
