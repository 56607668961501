import { sortBy } from 'lodash';
import staticMatchRequests from './_helpers/staticMatchRequests';
import { generateFormData, apiPost, apiStore } from '../../modules/apiHelper';
import {
  // eslint-disable-next-line no-unused-vars
  isStaleData, invalideDate, now, objectIsValid, toBoolean,
} from '../../modules/dataHelper';


export default {
  /**
   * * does not cache the matches because it will cache duplicate users' data from getUserData's cache design
   * ! How to cache only the matches data without the mentor/mentee's data
   */
  async getMatches(context, [matchState, CompanyId]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    //
    console.log(matchState, CompanyId);
    const apiEndpoint = context.state.matchStatesApiUrlActions[matchState];

    if (!apiEndpoint) return false;

    // const matches = await apiStore.getItem(`matches/${companyid}`) || [invalideDate, []];

    // xhr then update store
    const request = apiPost(apiEndpoint, generateFormData({ companyid }), 7)
      .catch((err) => {
        console.warn('getMatches::matches err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (res.data.error) return false;

        const mat = [];
        const req = [];

        await res.data[apiEndpoint].forEach(async (m) => {
          const details = new Promise((resolve, reject) => {
            Promise.all([
              context.dispatch('getUserData', m.mentorid),
              context.dispatch('getUserData', m.menteeid),
              context.dispatch('getLastMatchCommunicationDate', m),
            ]).catch((err) => {
              reject(err);
            }).then(([mentor, mentee, lastCommunicationDate]) => {
              if (!objectIsValid(mentor || {}) || !objectIsValid(mentee || {})) {
                resolve(true);
                return;
              }

              mat.push({ ...m, ...{ mentor, mentee }, lastCommunicationDate });

              resolve(true);
            });
          });

          req.push(details);
        });

        await Promise.all(req);

        if (mat.length) {
          apiStore.setItem(`matches/${companyid}`, [now(), mat]);
        }

        return mat;
      });

    // if (!isStaleData(matches[0]) && matches[1].length) return matches[1];

    // wait for the xhr
    const result = await request;
    return result;
  },

  // eslint-disable-next-line no-unused-vars
  async getMatchSuggestions(context, { genders = [1, 2], menteeId, groupId }) {
    // console.log(menteeId);
    if (!groupId || !menteeId) return false;

    /**
     * goals: {Array}                     // chosen goals // [UPDATE V2]
     * groupid: {id}                      // pillar group id
     * gender: {id|Array|Stringed Array}  // male(1), female(2)
     * check: {Number}                    // check for both genders
     */
    const formData = generateFormData({
      // goals,
      // gender: JSON.stringify(genders),
      groupid: groupId,
      // gender: genders[0],
      // check: ((genders.length > 1) ? 1 : 0),
      mentee: menteeId,
    });

    const results = await apiPost('company-suggestions', formData, 7)
      .catch(() => [])
      // .catch(() => {
      //   apiStore.removeItem('UserViewData/findmentors');
      //   return [];
      // })
      .then(async (res) => {
        if (!res) return [];
        if (res.data.error) return [];
        const mentorsReq = [];
        const mentors = [];

        res.data.Mentors_available.forEach((mentor) => {
          mentorsReq.push(context.dispatch('getUserData', mentor.mentorid, { root: true }));
        });

        await Promise.all(mentorsReq)
          .then((m) => {
            mentors.push(...m);
          });


        //
        // console.log(mentors);
        return mentors;
      });

    return results;
  },

  getPendingMatchRequests() {},

  getDelayedMatchRequests() {
    //
    return staticMatchRequests;
  },

  /**
   * api: .../chat/?action=chat_list // ? should be done on the backend
   * returns: tym
   * sortby tym_unix
   */
  async getLastMatchCommunicationDate(context, { mentorid, menteeid }) {
    const formData = new FormData();
    formData.set('mentorid', mentorid);
    formData.set('menteeid', menteeid);

    const lastDate = await apiPost('chat_list', formData, 4)
      .then((res) => {
        if (!res) return false;

        const messages = sortBy(res.data.chat_lists, 'tym_unix');

        return (messages[messages.length - 1] || {}).tym;
      });
    //

    return lastDate;
  },

  async getMenteeMentors(context, menteeid) {
    if (!menteeid) return false;

    const result = await apiPost('matched_mentee', generateFormData({ mentee: menteeid }), 1)
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        const mentors = [];
        const requests = [];

        res.data.matched_mentee.forEach((mentor) => {
          const request = context.dispatch('getUserData', mentor.mentorid)
            .then((user) => {
              if (user) {
                mentors.push(user);
              }
            });
          //

          requests.push(request);
        });

        await Promise.all(requests);

        return mentors;
      });
    //

    return result;
  },

  async createMatch(context, { mentorId, menteeId, groupId = 1 }) {
    if (!groupId || !menteeId || !mentorId) return false;

    const formData = generateFormData({
      mentor: mentorId,
      mentee: menteeId,
      group: groupId,
    });

    const result = await apiPost('match', formData, 1)
      .catch((err) => {
        console.warn('createMatch::match err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (res.data.error) return false;

        return true;
      });
    //

    return result;
  },

  async endMatch(context, { menteeId, mentorId, groupId }) {
    if (!groupId || !menteeId || !mentorId) return false;

    const formData = generateFormData({
      mentor: mentorId,
      mentee: menteeId,
      groupid: groupId,
    });

    const result = await apiPost('match_end', formData, 1)
      .catch((err) => {
        console.warn('endMatch::match_end err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (res.data.error) return false;

        return true;
      });
    //

    return result;
  },
};
